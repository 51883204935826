import React from 'react'

const SelectedRound = (props) => {

  const {round, back} = props

  const BlessingButton = () =>{
    return(
      <div className='uppercase rounded-full border-2 border-white bg-black w-max px-8 gap-1 flex items-center pt-2 pb-1'>
        <button>yes</button>/<button>no</button>
      </div>
    )
  }

  return (
    <div className='lg:container'>
      <div className='text-white text-5xl xl:text-6xl text-center border-b-2 border-white pb-3 relative'>
        <button className='text-app-gray-100 flex gap-2 sm:gap-3 uppercase absolute left-4 sm:left-10 top-1/2 transform -translate-y-1/2 text-base items-center sm:text-xl' onClick={back}>
          <img src="/matchup_page/ico_back.png" alt='back' className='h-6' />
          back
        </button>
        <p>round {round.round}</p>
      </div>
      <div className='space-y-3 md:hidden w-full mt-12'>
        <div className='flex items-center text-white gap-1 font-bebasneue text-6xl w-full justify-center'>
          <div className='relative'>
            <img src="/matchup_page/time.svg" alt='time' className='w-24' />
            <p className='absolute left-1/4 transform -translate-x-1/2 top-1/2 -translate-y-1/2 pt-2'>0</p>
            <p className='absolute right-1/4 transform translate-x-1/2 top-1/2 -translate-y-1/2 pt-2'>2</p>
          </div>
          <p className='text-4xl'>:</p>
          <div className='relative'>
            <img src="/matchup_page/time.svg" alt='time' className='w-24' />
            <p className='absolute left-1/4 transform -translate-x-1/2 top-1/2 -translate-y-1/2 pt-2'>3</p>
            <p className='absolute right-1/4 transform translate-x-1/2 top-1/2 -translate-y-1/2 pt-2'>1</p>
          </div>
          <p className='text-4xl'>:</p>
          <div className='relative'>
            <img src="/matchup_page/time.svg" alt='time' className='w-24' />
            <p className='absolute left-1/4 transform -translate-x-1/2 top-1/2 -translate-y-1/2 pt-2'>0</p>
            <p className='absolute right-1/4 transform translate-x-1/2 top-1/2 -translate-y-1/2 pt-2'>0</p>
          </div>
        </div>
        <div className='flex justify-center uppercase text-white text-xl gap-4'>
          <p className='w-24 text-center'>hrs</p>
          <p className='w-24 text-center'>mins</p>
          <p className='w-24 text-center'>secs</p>
        </div>
      </div>
      <div className='flex justify-around px-12 py-8 flex-col md:flex-row gap-8 items-center'>
        <div className={`rounded-full border-8 border-app-${round.first.name} overflow-hidden`}>
          <img src={round.first.image} alt="first" className={`scale-105 transform`} />
        </div>
        <div className='flex flex-col items-center justify-around gap-4 flex-grow'>
          <div className='md:block hidden space-y-3'>
            <div className='flex items-center text-white gap-1 font-bebasneue text-6xl xl:text-8xl '>
              <div className='relative'>
                <img src="/matchup_page/time.svg" alt='time' className='w-36' />
                <p className='absolute left-1/4 transform -translate-x-1/2 top-1/2 -translate-y-1/2 pt-2 xl:pt-3'>0</p>
                <p className='absolute right-1/4 transform translate-x-1/2 top-1/2 -translate-y-1/2 pt-2 xl:pt-3'>2</p>
              </div>
              <p className='text-4xl'>:</p>
              <div className='relative'>
                <img src="/matchup_page/time.svg" alt='time' className='w-36' />
                <p className='absolute left-1/4 transform -translate-x-1/2 top-1/2 -translate-y-1/2 pt-2 xl:pt-3'>3</p>
                <p className='absolute right-1/4 transform translate-x-1/2 top-1/2 -translate-y-1/2 pt-2 xl:pt-3'>1</p>
              </div>
              <p className='text-4xl'>:</p>
              <div className='relative'>
                <img src="/matchup_page/time.svg" alt='time' className='w-36' />
                <p className='absolute left-1/4 transform -translate-x-1/2 top-1/2 -translate-y-1/2 pt-2 xl:pt-3'>0</p>
                <p className='absolute right-1/4 transform translate-x-1/2 top-1/2 -translate-y-1/2 pt-2 xl:pt-3'>0</p>
              </div>
            </div>
            <div className='flex justify-around uppercase text-white gap-3 text-xl'>
              <p className='w-20 text-center'>hrs</p>
              <p className='w-20 text-center'>mins</p>
              <p className='w-20 text-center'>secs</p>
            </div>
          </div>
          <div className='text-white flex gap-6 md:gap-0 text-5xl xl:text-6xl items-center w-full'>
            <p className={`text-app-${round.first.name} w-2/5 text-right `}>{round.first.name}</p>
            <p className='text-3xl w-1/5 text-center'>VS</p>
            <p className={`text-app-${round.second.name} w-2/5 `}>{round.second.name}</p>
          </div>
        </div>
        <div className={`rounded-full border-8 border-app-${round.second.name} overflow-hidden`}>
          <img src={round.second.image} alt="second" className={`scale-105 transform`} />
        </div>
      </div>
      <div className='w-full uppercase p-2 sm:p-6 font-bebasneue tracking-wider'>
        <div className='md:shadow-inner-xl p-2 tiny:p-8 w-full flex md:flex-row flex-col gap-6 md:gap-0 justify-around rounded-xl text-center text-white text-3xl'>
          <div className='flex flex-col gap-8 shadow-inner-xl md:shadow-none rounded-xl py-10 md:py-0 w-full md:w-1/3'>
            <p className={`text-app-${round.first.rarity.type} whitespace-nowrap`}>{round.first.rarity.type} ({round.first.rarity.value}%)</p>
            <p className={`text-app-${round.first.strength.value > 0 ? "green" : "red"}`}>{round.first.strength.target} ({round.first.strength.value}%)</p>
            {round.first.blessing ? <div className='flex items-center justify-center'><BlessingButton /></div> : <p>undecided</p>}
            <p className={`text-app-${round.first.oddDetail > round.second.oddDetail ? "green" : "red"}`}>{round.first.oddDetail}%</p>
          </div>
          <div className='flex flex-col gap-8 shadow-inner-xl md:shadow-none rounded-xl py-10 md:py-0 w-full md:w-1/3 px-4 break-all'>
            <p>rarity</p>
            <p>strength/weekness</p>
            <p>god's blessing</p>
            <p>odds</p>
          </div>
          <div className='flex flex-col gap-8 shadow-inner-xl md:shadow-none rounded-xl py-10 md:py-0 w-full md:w-1/3'>
            <p className={`text-app-${round.second.rarity.type}`}>{round.second.rarity.type} ({round.second.rarity.value}%)</p>
            <p className={`text-app-${round.second.strength.value > 0 ? "green" : "red"}`}>{round.second.strength.target} ({round.second.strength.value}%)</p>
            {round.second.blessing ? <div className='flex items-center justify-center'><BlessingButton /></div> : <p>undecided</p>}
            <p className={`text-app-${round.first.oddDetail < round.second.oddDetail ? "green" : "red"}`}>{round.second.oddDetail}%</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectedRound