import React, { useState } from 'react'
import { totalRounds } from './data'
import SelectedRound from './components/popup'
import RoundItem from './components/rounditem'
import './matchup.scss'

const MatchUpPage = () => {

  const [activeRound, setActiveRound] = useState(null)

  return (
    <div className='match-container py-24 md:py-36 flex flex-col items-center' >
      {activeRound ?
        <SelectedRound round={activeRound} back={() => setActiveRound(null)}/>
        : <div className='flex flex-col gap-10 tiny:gap-8 xl:gap-16 container px-4 tiny:px-6 sm:px-10'>
          <p className='text-5xl xl:text-6xl text-white text-center'>next matches</p>
          {totalRounds.map((roundData, idx) =>
            <RoundItem roundData={roundData} key={idx} action={setActiveRound} />
          )}
        </div>}
    </div>
  )
}

export default MatchUpPage