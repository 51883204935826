export const totalRounds = [
  {
    round: 1,
    first: {
      name: "thor",
      image: "/matchup_page/thor.png",
      odd: "+400",
      oddDetail: '45',
      rarity: {
        type:"legendary",
        value:"+25"
      },
      strength: {
        target: "fenrir",
        value: "+5"
      },
      blessing: true,
    },
    second: {
      name: "surtr",
      image: "/matchup_page/surtr.png",
      odd: "-400",
      oddDetail: '55',
      rarity: {
        type:"common",
        value:"-"
      },
      strength: {
        target: "loki",
        value: "-5"
      },
      blessing: false,
    }
  },
  {
    round: 2,
    first: {
      name: "ymir",
      image: "/matchup_page/ymir.png",
      odd: "+400",
      oddDetail: '45',
      rarity: {
        type:"legendary",
        value:"+25"
      },
      strength: {
        target: "fenrir",
        value: "+6"
      },
      blessing: true,
    },
    second: {
      name: "loki",
      image: "/matchup_page/loki.png",
      odd: "-400",
      oddDetail: '55',
      rarity: {
        type:"common",
        value:"-"
      },
      strength: {
        target: "loki",
        value: "-5"
      },
      blessing: false,
    }
  },
  {
    round: 3,
    second: {
      name: "thor",
      image: "/matchup_page/thor.png",
      odd: "+400",
      oddDetail: '45',
      rarity: {
        type:"legendary",
        value:"+25"
      },
      strength: {
        target: "fenrir",
        value: "+5"
      },
      blessing: true,
    },
    first: {
      name: "surtr",
      image: "/matchup_page/surtr.png",
      odd: "-400",
      oddDetail: '55',
      rarity: {
        type:"common",
        value:"-"
      },
      strength: {
        target: "loki",
        value: "-5"
      },
      blessing: false,
    }
  },
  {
    round: 4,
    second: {
      name: "jormungand",
      image: "/matchup_page/ymir.png",
      odd: "+400",
      oddDetail: '45',
      rarity: {
        type:"legendary",
        value:"+25"
      },
      strength: {
        target: "fenrir",
        value: "+6"
      },
      blessing: true,
    },
    first: {
      name: "loki",
      image: "/matchup_page/loki.png",
      odd: "-400",
      oddDetail: '55',
      rarity: {
        type:"common",
        value:"-"
      },
      strength: {
        target: "loki",
        value: "-5"
      },
      blessing: false,
    }
  },
]