import React from 'react'
import Layout from '../components/layout/layout'
import MatchUpPage from '../components/partials/MatchUpPage'

const MatchUp = () =>{
  return(
    <Layout page="matchUp">
      <MatchUpPage />
    </Layout>
  )
}

export default MatchUp