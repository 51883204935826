import React from 'react'

const RoundItem = (props) => {

  const { roundData, action } = props

  return (
    <div role="button" tabIndex={0} className='px-4 py-4 flex flex-col sm:flex-row justify-between bg-white tiny:container w-full rounded-xl cursor-pointer' onClick={(e) => { e.stopPropagation(); action(roundData) }} onKeyDown={(e) => { e.stopPropagation(); action(roundData) }}>
      <div className='sm:hidden uppercase text-app-gray text-4xl px-5 py-2'>
        ROUND {roundData.round}
      </div>
      <div className='flex pt-2 sm:pt-0'>
        <img src={roundData.first.image} alt={roundData.first.name} className="w-1/2 sm:w-max" />
        <div className='sm:hidden flex items-end justify-center flex-col w-1/2 gap-3 px-4'>
          <p className={`text-4xl tiny:text-4xl lg:text-5xl text-app-${roundData.first.name} break-all text-right`}>{roundData.first.name}</p>
          <div className='flex gap-2 items-center'>
            <img src={`/matchup_page/${roundData.first.odd > 0 ? "up" : "down"}-flash.png`} alt="first flash" className='h-5' />
            <p className='text-2xl font-poppins font-bold '>{roundData.first.odd}</p>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-between w-full gap-4 sm:gap-0 py-2 sm:py-0 px-6 sm:px-0 relative'>
        <div className='h-full w-1 border-r border-app-gray-light absolute left-1/4 top-0 sm:hidden'></div>
        <div className='text-center uppercase text-app-gray sm:text-3xl lg:text-5xl border-b-2 border-app-gray-light py-2 hidden sm:block'>
          ROUND {roundData.round}
        </div>
        <div className='flex justify-end sm:justify-around w-full items-center'>
          <p className={`w-2/5 text-center break-all hidden sm:block text-2xl tiny:text-3xl lg:text-5xl text-app-${roundData.first.name}`}>{roundData.first.name}</p>
          <div className='flex items-center justify-center w-1/5'>
            <img src='/matchup_page/vs.png' alt='vs' className='h-10 sm:h-8 lg:h-10' />
          </div>
          <p className={`w-2/5 text-center break-all hidden sm:block text-2xl tiny:text-3xl lg:text-5xl text-app-${roundData.second.name}`}>{roundData.second.name}</p>
        </div>
        <div className='flex justify-end sm:justify-around w-full items-center'>
          <div className='hidden sm:flex gap-2 items-center w-2/5 justify-center'>
            <img src={`/matchup_page/${roundData.first.odd > 0 ? "up" : "down"}-flash.png`} alt="first flash" className='h-4 lg:h-5' />
            <p className='text-xl lg:text-2xl font-poppins font-bold '>{roundData.first.odd}</p>
          </div>
          <div className='flex items-center justify-center w-1/5'>
            <img src='/matchup_page/odds.png' alt='odd' className='h-4 lg:h-6' />
          </div>
          <div className='hidden sm:flex gap-2 items-center w-2/5 justify-center'>
            <p className='text-xl lg:text-2xl font-poppins font-bold '>{roundData.second.odd}</p>
            <img src={`/matchup_page/${roundData.second.odd > 0 ? "up" : "down"}-flash.png`} alt="second flash" className='h-4 lg:h-5' />
          </div>
        </div>
      </div>
      <div className='flex pb-2 sm:pb-0'>
        <img src={roundData.second.image} alt={roundData.second.name} className="w-1/2 sm:w-max" />
        <div className='sm:hidden flex items-end justify-center flex-col w-1/2 gap-3 px-4'>
          <p className={`text-4xl tiny:text-4xl lg:text-5xl text-app-${roundData.second.name} break-all text-right`}>{roundData.second.name}</p>
          <div className='flex gap-2 items-center'>
            <img src={`/matchup_page/${roundData.second.odd > 0 ? "up" : "down"}-flash.png`} alt="second flash" className='h-5' />
            <p className='text-2xl font-poppins font-bold '>{roundData.second.odd}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoundItem